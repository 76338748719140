// custom.less

// ***************

.footer .footer-celogo {
	margin-top: 8px;
}

.jumbotron {
	margin-top: 5px;
	padding: 0;
}
.jumbotron .container {
	position: relative;
	height: 400px;
}
.jumbotron-image {
	position: absolute;
	top: 0px;
	right: 0px;
	height: 400px;
	z-index: 10;

	img {
		height: 100%;
	}
}
.jumbotron-content {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 400px;
	z-index: 11;

	h1 {
		display: none;
		position: absolute;
		left: 0;
		top: 30px;
		text-shadow: 1px 1px 6px rgba(51,122,183,1);
	}

	p {
		position: absolute;
		top: 200px;
		left: 0px;
		width: 75%;
		text-shadow: 1px 1px 6px rgba(51,122,183,1);
	}

	a {
		color: #fff;
		text-decoration: none;
		text-transform: uppercase;
		font-size: 0.9em;
		opacity: 0.8;
		border-bottom: 1px solid #fff;
	}
	a:hover, a:active {
		text-decoration: none;
		opacity: 1;
	}

	h4 {
		position: absolute;
		left: 0;
		bottom: 40px;

		a {
			display: block;
			width: 190px;
			background-color: rgba(255,255,255,0.8);
			border: 1px solid rgba(255,255,255,0.1);
			border-radius: @border-radius-base;
			padding: 6px 10px;

			font-size: 0.7em;
			color: @link-color;
			opacity: 1;

			&:hover, &:visited, &:focus, &:active {
				background-color: rgba(255,255,255,1);
				opacity: 1;
			}
		}
	}
}
.jumbotron-link {
	position: absolute;
	left: 0;
	bottom: 15px;
}

@media screen and (max-width: (@screen-xs-min - 1)) {
	.jumbotron-content {
		margin: 0 10px;
	}
	.jumbotron {
	  p {
	  	font-size: (@font-size-base * 1.2);
			font-weight: 200;
	  }
	}
}
@media screen and (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
	.jumbotron-content {
		margin: 0 30px;
	}
}
@media screen and (min-width: @screen-sm-min) {
  .jumbotron-content {
	  h1 {
	  	display: block;
	    font-size: (@font-size-base * 3.5);
	  }
	  p {
	  	top: 120px;
	  }
	}
}
@media screen and (min-width: @screen-md-min) {
	.jumbotron-content p {
		width: 60%;
	}
}

// ***************
// modal

@media screen and (max-width: @screen-xs-max) { // < 768px
	.modal {
		.m-window {
			top: 0;
			width: 100%;
			height: 100%;
			margin-bottom: 0;
			border: 0 none;

			.m-content {
				background-color: #fff;
			}
		}
	}
	.modal-msg {
		border: 0 none;
	}
	body.modal-bob-open .body-content {
		display: none;
	}
}

// *****
// ios

.ios {
  .modal {
  	position: absolute;
  	.m-background {
  		display: none;
  		position: absolute;
  	}
    .m-window {
      top: 0;
      left: 0;
      width: 100%;
			height: 100%;
      margin-left: 0;
			margin-bottom: 0;
			border: 0 none;
      border-radius: 0;

      .m-content {
				background-color: #fff;
      	// width: 540px;
      	// margin: 0 auto;
      }
    }
  }
  .modal-msg {
    top: 0;
    left: 0;
    width: 100%;
    margin-left: 0;
    border-radius: 0;
		border: 0 none;
    .msg-content {
      border-radius: 0;
    }
  }
	body.modal-bob-open .body-content {
		display: none;
	}
}
@media screen and (min-width: @screen-sm-min) { // >= 768px
	.ios {
	  .modal {
	    .m-window {
	      .m-content {
	      	width: 540px;
	      	margin: 0 auto;
	      }
	    }
	  }
	}
}

// ***************
